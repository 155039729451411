import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import Button from '../Button';
import {Question} from '../Answer';
import './QuestionsPage.scss';
import {fetchQuestions} from '../../api';

export default function QuestionsPage({ws, role, registerMsgCallback}: {
    ws?: WebSocket | undefined,
    role: string,
    registerMsgCallback: (cb : {cb: (e : MessageEvent) => void})=> void}) {
  const [questions, setQuestions] = useState<Question[]>([]);
  let navigate = useNavigate();
  useEffect(() => {
    const getQuestions = async () => {
      const { data } = await fetchQuestions();
      setQuestions(data);
    };

    getQuestions();
  }, []);
    useEffect(() => {
        console.log('ws questions page', ws);
        if (ws) {
            const wsListener = (event: MessageEvent) => {
                if (!event) {
                    return
                }
                let response = JSON.parse(event.data);
                if (response.type === 'heart_beat_expired') {
                    navigate('/');
                } else {
                    console.log('onmessage', response);
                }
            }
            registerMsgCallback({cb: wsListener});
        }
    }, [ws]);
  return (
    <div className="questionsPage">
      <div className="questionsPage-title">Вопросы</div>
      <div className='questionsPage-questions'>
        {
          questions.map(question => (
            <div
              key={question.id}
              className='questionsPage-question'
            >
              {question.id}. {question.text}
              <Button
                onClick={() => navigate(`/question/${question.id}`)}
                text=""
              />
            </div>
          ))
        }
      </div>
      <div className="questionsPage-buttons">
        {
          (role === 'jury') ? <Button
            onClick={() => navigate('/')}
            text="Назад"
          /> : <></>
        }
        <Button
          onClick={() => navigate('/ranking')}
          text="К рейтингу команд"
        />
      </div>
    </div>
  );
}
