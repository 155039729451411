import React, {useEffect, useRef, useState} from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import AnswersPage from './Components/Judges/AnswersPage';
import QuestionsPage from './Components/Judges/QuestionsPage';
import QuestionPage from './Components/QuestionPage';
import TeamPage from './Components/TeamPage';
import './App.scss';
import RankingPage from './Components/RankingPage';
import {setHBTime, getHBTime} from './helpers/question';



function App() {
  let [role, setRole] = useState(window.localStorage.getItem('role'));
  let [token, setToken] = useState(window.localStorage.getItem('token'));
  let [ws, setWebsocket] = useState<WebSocket>();
  const msgCallbackRef = useRef<(e: MessageEvent) => void>();

  const createWebsocket = () => {
    if (!`${token}`) {
      console.log("Socket connection cancelled because token is not exist");
      return;
    }

    let currentUrl = new URL(window.location.href)
    if(`${process.env.NODE_ENV}` === "development") {
      currentUrl = new URL(`${process.env.REACT_APP_BASE_URL}`);
    }
    //let baseUrl = currentUrl.origin
    console.log("currentUrl = " + currentUrl)
    let sockProtocol = "ws";
    if (currentUrl.protocol === 'https:') {
      sockProtocol = "wss"
    }
    setHBTime(0);
    console.log(`connecting ${sockProtocol}...`);
    let newWs = new WebSocket(`${sockProtocol}://${currentUrl.host}?token=${token}`);
    newWs.onopen = () => {
      console.log('connected');
      setWebsocket(newWs);
    };
    newWs.onerror = (err) => {
      console.log('err = ' + err);
    };

    let wsInterval = setInterval(() => {
      if (!newWs || newWs.readyState !== newWs.OPEN) {
        clearInterval(wsInterval);
        createWebsocket();
      } else {
        let hbTime = getHBTime();
        let now = Date.now()
        if (hbTime !== 0 && (now - hbTime) > 4000) {
          console.log("HB Timeout, delay = ", (now - hbTime), " ms. Close the socket to reconnect to the future")
          // HB is not reset by HB answer for last time. Close socket to reconnnect
          newWs.close()
        } else {
          let now = Date.now()
          setHBTime(now);
          newWs.send(JSON.stringify(`{"type": "heart_beat", "hbDate":${now}}`));
        }
      }
    }, 5000);
  }

  const onAuth = async ({role, token} : {role: string, token: string}) => {
    setToken(token);
    setRole(role);
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('role', role);
    //createWebsocket();
    // it will be called later after token is initialized
  }

  const registerMsgCallback = async ({ cb }: { cb: (e: MessageEvent) => void }) => {
    console.log('registering callback', cb);
    msgCallbackRef.current = cb; // сохраняем текущий callback
  };

  useEffect(() => {
    console.log('ws app page', ws);
    if (ws) {
      ws.onmessage = (event) => {
        let response = JSON.parse(event.data);
        if (response.type === 'heart_beat') {
          setHBTime(0)
          if (Math.round(new Date().getTime() - response.hbDate) / 1000 > 4) {
            if (msgCallbackRef.current) {
              const hbMsg = new MessageEvent("message", {
                data: event.data.replace("heart_beat", "heart_beat_expired"),
              });
              msgCallbackRef.current(hbMsg);
            }
          }
        } else if (msgCallbackRef.current) {
            msgCallbackRef.current(event); // вызываем текущий callback через ref
        } else {
          console.log('onmessage in app ', response);
        }
      }
    }
  }, [ws]);

  useEffect(() => {
    if (!ws && token) {
      createWebsocket();
    }
  }, [token]);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <QuestionPage 
              ws={ws}
              role={role}
              onAuth={onAuth}
              token={token}
              registerMsgCallback={registerMsgCallback}
            />
          }/>
          <Route path="/questions" key={window.location.pathname} element={
            (role === 'jury' || role === 'checker')
                ? <QuestionsPage ws={ws} role={role} registerMsgCallback={registerMsgCallback}/>
                : <Navigate to='/' replace/>
          }/>
          <Route path="ranking" key={window.location.pathname} element={
            (role === 'jury' || role === 'checker')
                ? <RankingPage/>
                : <Navigate to='/' replace/>
          }/>
          <Route path="/question/:questionId" key={window.location.pathname} element={
            (role === 'jury' || role === 'checker')
                ? <AnswersPage />
                : <Navigate to='/' replace/>
          }/>
          <Route path="/team" key={window.location.pathname} element={
            (role === 'user')
                ? <TeamPage ws={ws} registerMsgCallback={registerMsgCallback}/>
                : <Navigate to='/' replace/>
          }>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
