import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import TeamHeader from './TeamHeader';
import {saveExpiry, saveQuestion} from '../helpers/question';
import './TeamPage.scss';
import {Question} from './Answer';
import TeamAnswers from './TeamAnswers';
import {fetchTeamInfo, getQuestionInfo} from '../api';

export default function TeamPage({ ws , registerMsgCallback } : {
  ws?: WebSocket,
  registerMsgCallback: (cb : {cb: (e : MessageEvent) => void})=> void}) {

  const [teamInfo, setTeamInfo] = useState({
    name: '',
    questions: []
  });
  let navigate = useNavigate();

  async function restoreQuestionNotExpired(data: any) {
    let questionInfo = await getQuestionInfo(data.questionId);
    if (questionInfo.status !== 200) {
      // redirect to question pages via '/' (routes) if not answered
      navigate('/');
    }
  }



  const getPoints = (questions: Question []) => {
    let correctCount = 0;
    questions.forEach(question => {
      if (question.answer?.correct === 'true') correctCount++;
    });
    return correctCount;
  };

  const getQuestions = async () => {
    let { data } = await fetchTeamInfo();
    setTeamInfo(data);
  };

  useEffect(() => {
    getQuestions();
  }, []);

  useEffect(() => {
        console.log('ws team page', ws);
        if (ws) {
          const wsListener = (event: MessageEvent) => {
            if (!event) {
              return
            }

            let response = JSON.parse(event.data);
            if (response.type === 'question') {
              saveQuestion(response.data.questionId);
              saveExpiry(response.data.deadline);
              restoreQuestionNotExpired(response.data)
            } else if (response.type === 'rating') {
              console.log(response.data);
              console.log(teamInfo);
              getQuestions();
            } else if (response.type === 'heart_beat_expired') {
                navigate('/');
            } else {
              console.log('onmessage', response);
            }
          }
          registerMsgCallback({cb: wsListener});
        }
      },
      [ws, navigate]);

  return (
    <div className="team">
      <TeamHeader
        teamName={teamInfo.name}
        points={getPoints(teamInfo.questions)}
        totalQuestions={teamInfo.questions.length}
      />
      <TeamAnswers 
        questions={teamInfo.questions}
      />
    </div>
  );
}
