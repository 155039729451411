import React, {useState, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import './InputArea.scss';

export default function InputArea({ onSubmit, buttonText, isJury, isQuestionActive, teams, team, setTeam, currentQuestion } : { onSubmit : ( text: string, team?: string ) => Promise<void>, buttonText: string, isJury: boolean, isQuestionActive: boolean, team: string, teams: Array<{id: string, name: string}>, setTeam: (e: string ) => void, currentQuestion: number | null}) {
  let [text, setText] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  //const navigate = useNavigate();
  const textInput = useRef<HTMLInputElement>(null);

  let handleSubmit = async (e?: React.ChangeEvent<HTMLFormElement>) => {
    console.log('submited');
    if (e) {
      e.preventDefault();
    }
    setSubmitting(true);

    setTimeout(() => {
      setSubmitting(false);
    }, 2000);
    await onSubmit(text, team);
  };

  // const onAreaClick = () => {
  //   textInput.current?.focus();
  // }

  return (
    <form
      autoComplete="off"
      onSubmit={(e: React.ChangeEvent<HTMLFormElement>) => handleSubmit(e)}
      className="inputArea"
      // onClick={onAreaClick}
    >
      <div className="inputArea-buttons">
        {/* {
          (isJury) ? (
            <Button
              text="Ответы"
              onClick={() => navigate('/questions')}
            />) : null
        } */}
        {/* {
          (isJury) ? (
            <select
              className="inputArea-select"
              onChange={(e) => setTeam(e.target.value)}
              value={team}
            >
              <option
                value=""
              >Все команды</option>
              {
                teams.map(team => (
                  <option
                    key={team.id}
                    value={team.id}
                  >{team.name}</option>
                ))
              }
            </select>
          ) : null
        } */}
        {
          currentQuestion ? <div>Вопрос №{isQuestionActive ? currentQuestion : currentQuestion + 1}</div> : null
        }
        <Button
          text={ buttonText }
          isSubmit
          disabled={ (isQuestionActive && isJury) || isSubmitting}
        />
      </div>
      <input
        type="text"
        className="inputArea-text"
        name="answer"
        value={text}
        ref={textInput}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
      ></input>
      <input type="submit" hidden />
    </form>
  );
}
